<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">회원 검색</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="memberName"
                  v-model="searchOptions.memberName"
                  :maxlength="20"
                  @keydown.enter="onSearchKeydownEnter"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">핸드폰 뒷 4자리</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="hpNoIdx"
                  v-model="searchOptions.hpNoIdx"
                  :maxlength="4"
                  @keydown.enter="onSearchKeydownEnter"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title"></div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="subtotalFlag"
                        @change="onSubTotalFlagChange"
                      />
                      <i />
                      <div class="label">개인별 소계 보기</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onSearchButtonClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">마일리지 적립/사용 현황 목록</div>
              <div class="header-caption">[{{ mileageAccrualUsageStatusCount }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                       @click.native="onAddButtonClicked"
                  >
                    추가
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="mileageAccrualUsageStatusGrid"
              v-bind="mileageAccrualUsageStatusGridProps"
              :dataSource="mileageAccrualUsageStatus"
              :aggregates="subtotalFlag ? mileageAccrualUsageStatusGridAggregates : mileageAccrualUsageStatusGridAggregatesTotal"
              @headerCellInfo="onGridHeaderCellInfo"
              @queryCellInfo="onGridQueryCellInfo"
              @recordClick="gridRecordClick"
              @actionComplete="onGridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-waitReservationRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">적립/사용</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="accmlUseDivDropdown"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.accmlUseDiv"
                        :dataSource="searchOptions.accmlUseDivOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">마일리지구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="mileageDivDropdown"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchOptions.mileageDiv"
                        :dataSource="searchOptions.mileageDivOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">발생일자</div>
                  <ul class="content">
                    <li class="item">
                      <input-date-range
                        v-model="searchDateInputDateRange"
                        type="lookupDetail-condition"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                @click.native="onSearchDetailViewButtonClick"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onSearchDetailPopupInitClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="searchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <mileage-accrual-usage-status-detail-popup
      v-if="isMileageAccrualUsageStatusDetailPopup"
      ref="mileageAccrualUsageStatusDetailPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="onMileageAccrualUsageStatusDetailPopupClosed"
    />
  </div>
</template>

<style scoped>
body .content-body .article-section .section-body {overflow: hidden; border: none}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import MileageAccrualUsageStatusDetailPopup from "@/views/golf-reservation/popup/MileageAccrualUsageStatusDetailPopup";

import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Group, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedPhoneNumber} from "@/utils/string";

import { orderBy as _orderBy } from "lodash";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from '@/utils/commonCodes';
import {numberWithCommas} from "@/utils/number";

export default {
  name: "mileageAccrualUsageStatus",
  components: {
    EjsGridWrapper,
    ErpButton,
    InputText,
    InputDateRange,
    MileageAccrualUsageStatusDetailPopup,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      subtotalFlag: false,
      isMileageAccrualUsageStatusDetailPopup: false,
      isSearchDetailPopupOpen: false,
      searchOptions: {
        occurDateFrom: null,
        occurDateTo: null,
        memberName: null,
        hpNoIdx: null,
        accmlUseDiv: [],
        mileageDiv: [],
        accmlUseDivOptions: commonCodesGetCommonCode(
          "ACCML_USE_DIV",
          true
        ),
        mileageDivOptions: commonCodesGetCommonCode(
          "MILEAGE_DIV",
          true
        ),
      },
      mileageAccrualUsageStatus: [],
      mileageAccrualUsageStatusCount: 0,
      mileageAccrualUsageStatusGridAggregates: [
        {
          columns: [
            {
              field: "mileageDiv",
              aggregationType: "GroupCaption",
              textAlign: "right",
              customAggregate: "잔여",
            },
            {
              field: "mileage",
              aggregationType: 'GroupSum',
            }
          ],
        }
      ],
      mileageAccrualUsageStatusGridAggregatesTotal: [
        {
          columns: [
            {
              field: "mileageDiv",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: "잔여",
            },
            {
              field: "mileage",
              aggregationType: "TotalSum",
            }
          ],
        }
      ],
      commonCodeFields: { text: "comName", value: "comCode" },
    };
  },
  computed: {
    isPopupOpened() {
      return false;
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.occurDateFrom,
          to: this.searchOptions.occurDateTo
        };
      },
      set: function (dateRange) {
        this.searchOptions.occurDateFrom = dateRange.from;
        this.searchOptions.occurDateTo = dateRange.to;
      }
    },
    mileageAccrualUsageStatusGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        allowPaging: true,
        groupSettings: {
          columns: ["groupData"],
          showDropArea: false,
        },
        pageSettings: { pageSize: 50 },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        columns: [
          {
            field: "mileageId",
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "groupData",
            type: "string",
            visible: false,
          },
          {
            field: "membershipId",
            type: "string",
            visible: false,
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            groupCode: "MEMBER_GRADE",
            isCommonCodeField: true,
          },
          {
            field: "hpNo",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            valueAccessor: this.telNumberValueAccess,
          },
          {
            field: "occurDate",
            headerText: "발생일자",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
          },
          {
            field: "accmlUseDiv",
            headerText: "적립/사용",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            groupCode: "ACCML_USE_DIV",
            isCommonCodeField: true,
          },
          {
            field: "mileageDiv",
            headerText: "마일리지구분",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            groupCode: "MILEAGE_DIV",
            isCommonCodeField: true,
          },
          {
            field: "mileage",
            headerText: "마일리지",
            type: "number",
            minWidth: 16,
            width: 120,
            textAlign: "right",
            format: "N",
          },
          {
            field: "stdInfo",
            headerText: "적립기준정보",
            type: "string",
            minWidth: 16,
            width: 400,
            textAlign: "left",
          },
          {
            field: "remarks",
            headerText: "비고",
            type: "string",
            minWidth: 16,
            width: 400,
            textAlign: "left",
          },
        ],
      };
    }
  },
  methods: {
    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },
    async onSearchButtonClick() {
      const args = {
        memberName: this.searchOptions.memberName,
        hpNoIdx: this.searchOptions.hpNoIdx,
        accmlUseDivs: this.searchOptions.accmlUseDiv,
        mileageDivs: this.searchOptions.mileageDiv,
        occurDateFrom: this.searchOptions.occurDateFrom || null,
        occurDateTo: this.searchOptions.occurDateTo || null,
      };
      console.log('args.===>', args);
      this.mileageAccrualUsageStatus = _orderBy((await GolfErpAPI.fetchMileageAccrualUsageStatus(args)).map(data => ({
        ...data,
        groupData: this.subtotalFlag ? ((data.memberName ? data.memberName : "") + data.membershipId) : "",
      })), ["memberName", "membershipId", "occurDate", "mileageId"]);
    },
    onSearchKeydownEnter() {
      this.onSearchButtonClick();
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onSearchDetailPopupInitClicked() {
      this.searchOptions.accmlUseDiv = [];
      this.searchOptions.mileageDiv = [];
      this.searchOptions.occurDateFrom = null;
      this.searchOptions.occurDateTo = null;
    },
    onSearchDetailViewButtonClick() {
      this.onSearchButtonClick();
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "memberName" || field === "mileageDiv" || field === "mileage") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          field
        },
        cell,
        data,
      } = args;

      if (field === "memberName" || field === "mileageDiv" || field === "mileage") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }

      if (field === "accmlUseDiv") {
        cell.style.color = commonCodesGetColorValue(
          "ACCML_USE_DIV",
          data.accmlUseDiv
        );
      } else if (field === "mileageDiv") {
        cell.style.color = commonCodesGetColorValue(
          "MILEAGE_DIV",
          data.mileageDiv
        );
      }
    },
    gridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "memberName" || field === "mileageDiv" || field === "mileage") {
        console.log('rowData.===>', rowData);
        this.isMileageAccrualUsageStatusDetailPopup = true;
        this.$nextTick(() => {
          this.$refs.mileageAccrualUsageStatusDetailPopup.showPopup(rowData);
        });
      }
    },
    onMileageAccrualUsageStatusDetailPopupClosed(isSaveFlag) {
      this.isMileageAccrualUsageStatusDetailPopup = false;

      if (isSaveFlag) {
        this.onSearchButtonClick();
      }
    },
    onSubTotalFlagChange() {
      this.onSearchButtonClick();
    },
    onAddButtonClicked() {
      this.isMileageAccrualUsageStatusDetailPopup = true;
      this.$nextTick(() => {
        this.$refs.mileageAccrualUsageStatusDetailPopup.showPopup();
      });
    },
    onGridActionComplete() {
      this.mileageAccrualUsageStatusCount = numberWithCommas(
        this.$refs.mileageAccrualUsageStatusGrid?.getGridBatchCount() || 0
      );
    },
  }
};
</script>
