<template>
  <div>
    <ejs-dialog
      ref="mileageAccrualUsageStatusDetailPopup"
      header="마일리지 정보"
      width="832"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :cssClass="
        mileage.mileageId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      @close="onPopupClosed"
    >
      <div class="window reservationInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">마일리지 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field memberNameNumber">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      회원명/번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input memberName">
                                          <div class="form">
                                            <input-text
                                              id="memberName"
                                              ref="memberName"
                                              v-model="
                                                mileage.memberName
                                              "
                                              placeholder="회원명"
                                              @change="onMemberNameChanged"
                                              @keydown.enter="
                                                memberNameInputKeyEnter
                                              "
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group text memberNo">
                                          {{ mileage.memberNo }}
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                  button-div="GET"
                                                  :is-icon-custom="true"
                                                  ref="memberPopupOpenButton"
                                                 @click.native="onMemberPopupOpen(true,memberPopupType.RESV, mileage.memberName,null)"
                                              >
                                                조회
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <component-telephone
                                              id="hpNo"
                                              name="hpNo"
                                              ref="hpNo"
                                              v-model="
                                                mileage.hpNo
                                              "
                                              :max-length="11"
                                              :disabled="true"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberDiv"
                                              v-model="
                                                mileage.memberDiv
                                              "
                                              :dataSource="
                                                mileageOptions.memberDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              :enabled="false"
                                              cssClass="body-data-dropdown"
                                              @change="
                                                onMemberDivDropDownListChanged
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원등급</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberGrade"
                                              v-model="
                                                mileage.memberGrade
                                              "
                                              :dataSource="
                                                mileageOptions.memberGradeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              :enabled="false"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field"></li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">발생일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              id="occurDate"
                                              ref="occurDate"
                                              v-model="
                                                mileage.occurDate
                                              "
                                              type="body-data"
                                              format="YYYY-MM-DD"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">적립/사용</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="accmlUseDiv"
                                              v-model="
                                                mileage.accmlUseDiv
                                              "
                                              :dataSource="
                                                mileageOptions.accmlUseDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                              @change="onAccmlUseDivChange"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">마일리지구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="mileageDiv"
                                              v-model="
                                                mileage.mileageDiv
                                              "
                                              :dataSource="
                                                mileageOptions.mileageDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="commonCodeFields"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">마일리지</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-number
                                              id="mileage"
                                              ref="mileage"
                                              v-model="
                                                mileage.mileage
                                              "
                                              :allowMinus="mileage.accmlUseDiv === 'USE'"
                                              :propMaxLength="9"
                                              @keydown.native="
                                                preventEnterEvent
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field stdInfo">
                                    <!-- 필수 : required -->
                                    <div class="title required">적립기준정보</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="stdInfo"
                                              ref="stdInfo"
                                              v-model="
                                                        mileage.stdInfo
                                                      "
                                              :maxlength="100"
                                              @keydown.native="
                                                        preventEnterEvent
                                                      "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <!-- 필수 : required -->
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              id="remarks"
                                              ref="remarks"
                                              v-model="
                                                        mileage.remarks
                                                      "
                                              :maxlength="100"
                                              @keydown.native="
                                                        preventEnterEvent
                                                      "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onPopupCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .windowContent .content-body .section-body .article-section.section-0101 .field.memberNameNumber .content .item.input.memberName {width:129px}
body .windowContent .content-body .section-body .article-section.section-0101 .field.memberNameNumber .content .item.text.memberNo {width:123px}
body .windowContent .content-body .section-body .article-section.section-0101 .field.stdInfo {width: calc((100% / 3 * 2) + 1px);}
body .windowContent .content-body .section-body .article-section.section-0101 .field.remarks {width: calc(100% + 1px);}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {formPreventEnterEvent, validateFormRefs} from "@/utils/formUtil";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetJsonData,
} from "@/utils/commonCodes";

import InputText from "@/components/common/text/InputText";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import ComponentTelephone from "@/components/ComponentTelephone";
import MemberSelectPopup from "@/views/common/MemberSelectPopup";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {deepDiffs} from "@/utils/ObjectUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "MileageAccrualUsageStatusDetailPopup",
  components: {
    InputText,
    InputDate,
    InputNumber,
    ComponentTelephone,
    MemberSelectPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {},
  data() {
    return {
      isMemberSelectPopupOpen: false,
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
      },
      orgMileage: {},
      mileage: {
        mileageId: null,
        memberName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        hpNo: null,
        occurDate: null,
        accmlUseDiv: null,
        mileageDiv: null,
        mileage: 0,
        payId: null,
        lotteryId: null,
        visitId: null,
        penltyId: null,
        salesId: null,
        stdInfo: null,
        remarks: null,
        delFlag: null,
        insertId: null,
        insertDt: null,
        updateId: null,
        updateDt: null,
      },
      mileageOptions: {
        memberDivOptions: [],
        memberGradeOptions: [],
        accmlUseDivOptions: [],
        mileageDivOptions: [],
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      isSave: false,
      validateRefList: {
        memberName: {
          required: true,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        occurDate: {
          required: true,
        },
        accmlUseDiv: {
          required: true,
        },
        mileageDiv: {
          required: true,
        },
        mileage: {
          required: true,
          custom: {
            method: (value) => {
              if (this.mileage.accmlUseDiv === "USE" && value < 0) {
                return true;
              } else if (this.mileage.accmlUseDiv !== "USE" && value > 0) {
                return true;
              }
            },
            message: "마일리지 값을 다시 확인해 주시기 바랍니다",
          },
        },
        stdInfo: {
          required: true,
        }
      },
      isFirstDataSet: false,
    };
  },
  created() {},
  destroyed() {},
  computed: {
    isModify() {
      return (this.mileage.mileageDiv === "");
    }
  },
  mounted() {
    this.mileageOptions.memberDivOptions = commonCodesGetCommonCode(
      "MEMBER_DIV"
    );
    this.mileageOptions.memberGradeOptions = commonCodesGetCommonCode(
      "MEMBER_GRADE"
    );
    this.mileageOptions.accmlUseDivOptions = commonCodesGetCommonCode(
      "ACCML_USE_DIV"
    );
    // this.mileageOptions.mileageDivOptions = commonCodesGetCommonCode(
    //   "MILEAGE_DIV"
    // );
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    async showPopup(data) {
      console.log('data.===>', data);

      if (data?.mileageId) {
        await this.fetchMileageByMileageId(data.mileageId);
      // } else {
      //   this.mileageOptions.mileageDivOptions = commonCodesGetCommonCodeByIdxAttrb(
      //     "MILEAGE_DIV",
      //     2,
      //     "ADMIN"
      //   );
      }

      this.orgMileage = JSON.parse(JSON.stringify(this.mileage));

      this.isFirstDataSet = true;
    },
    async fetchMileageByMileageId(mileageId) {
      this.mileage = await GolfErpAPI.fetchMileageByMileageId(mileageId);
    },
    onPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    onMemberNameChanged(args) {
      if (args.event && !!args.value) {
        // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
        // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명을 예약자명과 동일하게 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
        //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
        // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
        this.onMemberPopupOpen(
          false,
          this.memberPopupType.RESV,
          args.value,
          null
        );
      }
    },
    memberNameInputKeyEnter() {
      this.$refs.memberPopupOpenButton.focusIn();
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      memberData.nomDataViewFlag = false;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.mileageOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.mileage.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              console.log(memberGradeDefaultValue);
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.mileage.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    setResvMemberInfos(memberInfo) {
      if (this.mileage) {
        this.mileage.memberName = memberInfo.memberName;
        this.mileage.membershipId = memberInfo.membershipId;
        this.mileage.memberNo = memberInfo.memberNo;
        this.mileage.memberDiv = memberInfo.memberDiv;
        this.mileage.memberGrade = memberInfo.memberGrade;
        this.mileage.hpNo = memberInfo.hpNo;
      }
    },
    setResvNonMemberInfos() {
      if (this.mileage) {
        this.mileage.membershipId = "";
        this.mileage.memberNo = "";
        this.mileage.memberDiv = "NOM";
        this.mileage.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.mileage.hpNo = "";
      }
    },
    memberSelectPopupConfirmed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (data.selectedRowData.memberDiv == "NOM") {
            this.setResvNonMemberInfos();
          } else {
            this.setResvMemberInfos(data.selectedRowData);
          }
        }
      }
    },
    onMemberDivDropDownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.mileageOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode === this.mileage.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              console.log(memberGradeDefaultValue);
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.mileage.membershipId = "";
                this.mileage.memberNo = "";
                this.mileage.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            this.mileage.memberName,
            null,
            null,
            true
          );
        }
      }
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      if (!this.mileage.memberNo) {
        this.errorToast("회원을 입력해 주십시오");
        return;
      }

      const data = deepDiffs(this.orgMileage, this.mileage);

      if (!data) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      data.mileageId = this.mileage.mileageId;
      data.occurDate = this.mileage.occurDate;

      const mileageId = await GolfErpAPI.patchMileage(data);

      this.infoToast("저장되었습니다");

      this.isSave = true;

      this.showPopup({ mileageId: mileageId });
    },
    async onDeleteButtonClicked() {
      if (!this.mileage.mileageId) {
        this.infoToast("저장된 데이터가 아닙니다");
        return;
      }

      await GolfErpAPI.deleteMileage(this.mileage);

      this.isSave = true;

      this.onPopupCloseButtonClicked();
    },
    onPopupCloseButtonClicked() {
      this.$refs.mileageAccrualUsageStatusDetailPopup.hide();
    },
    onAccmlUseDivChange(args) {
      const {
        value
      } = args;

      if (this.isFirstDataSet) {
        this.mileage.mileageDiv = null;
        this.mileage.mileage = this.mileage.mileage * -1;
      }

      const attrbs = commonCodesGetCommonCodeByIdxAttrb("MILEAGE_DIV", 2, "ADMIN").map(data => data.comCode);

      this.mileageOptions.mileageDivOptions = commonCodesGetCommonCodeByIdxAttrb(
        "MILEAGE_DIV",
        1,
        value
      ).filter(data => this.mileage.mileageId ? true : attrbs.includes(data.comCode));
    },
  }
};
</script>
